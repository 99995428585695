// https://github.com/lancedikson/bowser#use-cases
import Bowser from 'bowser/bundled';

/*
 * This is a dedicated webpack entrypoint which only loads the browser
 * checking library and removes the app from the dom if the engine is not supported.
 */

// https://lancedikson.github.io/bowser/docs/global.html#assign
const supportedEngines = [
  // chromium (chrome + edge) + opera
  'Blink',
  // safari
  'WebKit',
  // firefox
  'Gecko',
];

const browser = Bowser.getParser(window.navigator.userAgent);

if (supportedEngines.indexOf(browser.getEngineName()) === -1) {
  document
    .getElementById('unsupportedBrowserContainer')
    .removeAttribute('style');

  const root = document.getElementById('root');
  if (root && typeof root.remove === 'function') {
    root.remove();
  }
  console.info(
    `Browser not supported, engine is: '${browser.getEngineName()}'`
  );

}
